import React from 'react';
import { getIconByName } from './getIconByName';

import { Image, ImageProps } from '../../next-components/image';
import { StyledSystemProps } from '../../../types';

type Props = {
  isInverted?: boolean;
  name?: string;
  hover?: boolean;
} & Partial<ImageProps> & Partial<StyledSystemProps>;

export const Icon = ({
  name,
  isInverted = false,
  ...props
}: Props) => (
  <Image
    transform={isInverted ? 'rotate(180deg)' : undefined} // Console errors if we don't do it this way
    alt="icon"
    src={getIconByName(name)}
    {...props}
  />
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { pxToNumber } from '../utils/pxToNumber';

import { useMediaQuery } from './useMediaQuery';
import { useTheme } from './useTheme';

/**
 * This is a helper hook that allows you to make a prop a "responsive prop"
 * A "responsive prop" allows you to pass a single value or array that corresponds
 * to different screen sizes. This is like a styled-system but instead of CSS properties
 * we use it on non-CSS properties to be responsive
 *
 * It currently supports mobile and desktop; Tablets aren't supported yet.
 */
export const useGetResponsivePropValue = (prop: any | any[]): any => {
  const { theme } = useTheme();

  const smBreakpoint = pxToNumber(theme.breakpoints[0]);

  const { matched: isXsDown } = useMediaQuery({ maxWidth: smBreakpoint - 1 });

  const getValue = () => {
    // Copy options to avoid direct mutations
    const copiedProp = Array.isArray(prop) ? [...prop] : prop;
    if (Array.isArray(copiedProp)) {
      return isXsDown ? copiedProp.shift() : copiedProp.pop();
    }
    return copiedProp;
  };

  const value = getValue();

  return value;
};

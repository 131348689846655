/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactNode,
  FunctionComponent,
  CSSProperties,
  AllHTMLAttributes,
  MutableRefObject,
} from 'react';

import styled, {
  CSSProp,
} from 'styled-components';
import {
  BackgroundProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  BackgroundColorProps,
  BorderProps,
  ColorProps,
  GridProps,
  PositionProps,
  ShadowProps,
  TypographyProps,
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
  ObjectOrArray,
} from 'styled-system';

type StringOrObjectOrArray<T, K extends keyof any = keyof any> = string | ObjectOrArray<T, K>;

type StyledSystemProps = BackgroundProps &
  BackgroundColorProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;

type Props = {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  boxSizing?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'border-box'
    | 'context-box';
  cursor?:
    | ' none'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | '-moz-grab'
    | '-webkit-grab'
    | 'alias'
    | 'all-scroll'
    | 'auto'
    | 'cell'
    | 'col-resize'
    | 'context-menu'
    | 'copy'
    | 'crosshair'
    | 'default'
    | 'e-resize'
    | 'ew-resize'
    | 'grab'
    | 'grabbing'
    | 'help'
    | 'move'
    | 'n-resize'
    | 'ne-resize'
    | 'nesw-resize'
    | 'no-drop'
    | 'none'
    | 'not-allowed'
    | 'ns-resize'
    | 'nw-resize'
    | 'nwse-resize'
    | 'pointer'
    | 'progress'
    | 'row-resize'
    | 's-resize'
    | 'se-resize'
    | 'sw-resize'
    | 'text'
    | 'vertical-text'
    | 'w-resize'
    | 'wait'
    | 'zoom-in'
    | 'zoom-out';
  css?: CSSProp;
  /**
   * ! FIXME: workaround to get around conflicting type definition of
   * `color` prop from `React.HTMLAttributes` (JSX attribute) and
   * `TextColorProps` (Styled System)
   */
  color?: any;
  children?: ReactNode;
  outline?: StringOrObjectOrArray<CSSProperties['outline'], CSSProperties['outline']>;
  textDecoration?: StringOrObjectOrArray<CSSProperties['textDecoration'], CSSProperties['textDecoration']>;
  transition?: StringOrObjectOrArray<CSSProperties['transition'], CSSProperties['transition']>;
  aspectRatio?: StringOrObjectOrArray<CSSProperties['aspectRatio'], CSSProperties['aspectRatio']>;
  objectFit?: StringOrObjectOrArray<CSSProperties['objectFit'], CSSProperties['objectFit']>;
  style?: CSSProperties;
  ref?: MutableRefObject<any>;
} & StyledSystemProps &
  Partial<Pick<HTMLElement, 'id'>>
  & Omit<AllHTMLAttributes<HTMLElement>, keyof StyledSystemProps | 'as'>;

export type BoxProps = Props;

const boxSizing = system({
  boxSizing: {
    property: 'boxSizing',
  },
});

const cursor = system({
  cursor: {
    property: 'cursor',
  },
});

const outline = system({
  outline: {
    property: 'outline',
  },
});

const textDecoration = system({
  textDecoration: {
    property: 'textDecoration',
  },
});

const transition = system({
  transition: {
    property: 'transition',
  },
});

const aspectRatio = system({
  aspectRatio: {
    property: 'aspectRatio',
  },
});

const objectFit = system({
  objectFit: {
    property: 'objectFit',
  },
});

const styleProps = compose(
  background,
  border,
  boxSizing,
  color,
  cursor,
  flexbox,
  grid,
  layout,
  outline,
  position,
  shadow,
  space,
  textDecoration,
  transition,
  aspectRatio,
  objectFit,
  typography,
);

export const Box = styled.div.attrs<Props, Props>(({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  boxSizing = 'border-box',
}) => ({
  boxSizing,
}))(styleProps) as unknown as FunctionComponent<React.PropsWithChildren<Props>>;

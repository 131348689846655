/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  SupportedImageLoaders,
  CustomImageLoader,
  LoaderFunction,
  LoaderOptions,
  cloudflareImageLoader,
} from './loaders';
import { useGetResponsivePropValue } from '../../common/hooks/useGetResponsivePropValue';
import { Box, BoxProps } from '../box';

type ImageLoader = SupportedImageLoaders | CustomImageLoader;

const loaderFnMap: Record<SupportedImageLoaders, LoaderFunction> = {
  cloudflare: cloudflareImageLoader,
};

export type ImageProps = {
  height?: any;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  ref?: any;
  width?: any;
  srcSet?: string;
  src?: string | string[];
  loader?: ImageLoader;
  loaderOptions?: LoaderOptions;
  transform?: string;
} & Omit<BoxProps, 'children' | 'src'> &
  Omit<JSX.IntrinsicElements['img'], 'height' | 'width' | 'src'>;

export const Image = ({
  srcSet,
  src,
  loader,
  loaderOptions,
  ...rest
}: ImageProps) => {
  const imageSrc = useGetResponsivePropValue(src);
  const loaderImageSrc = loader && (
    typeof loader === 'function'
      ? loader(imageSrc, loaderOptions)
      : loaderFnMap[loader](imageSrc, loaderOptions)
  );

  return (
    <Box
      as="img"
      src={loaderImageSrc || imageSrc}
      srcSet={srcSet}
      {...rest}
    />
  );
};
